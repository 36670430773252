window.loaded = false;
var windowWidth = 0;
var homeH1 = undefined;
var aboutUsInner = undefined;
var trustBoxes = undefined;

$(window).on('load', function(){
	window.loaded = true;
});

var headerBorder = undefined;

var mapContent = undefined;
var breakBoxCheck = false;
var boxCounter = 0;

var beforeAfterLoaded = {};

$(document).ready(function() {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function() {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;
			
			$(document).trigger('implant.window_width_changed', windowWidth);
		}
	}

	window.addEventListener('resize', resizeFn);

	$('.mailtolink').click(function(e) {
		handleMailtoLink($(this));
	});

	$('[data-purpose="navigation-link"]').each(function(){
		setNavigationUrl($(this));
	});
		
	if( $('.cookie-bar').length > 0 ) {

		$('.cookie-bar .button').click(function(){
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url:ajax_controller+'setCookieConfirm',
				dataType:'json',
				type:'post',
				data:{cookie:1},
				success: function(){}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function(){
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function(){
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		if (isNaN(timeout)) {
			timeout = 400;
		}
		
		if (item.length > 0) {
			$('html, body').animate({scrollTop: item.offset().top}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function(){
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function(){
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});

	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			
		}
	}


	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function(){
			openArticleGallery($(this));
		});
	}

	$(document).on('implant.image_is_loaded', function(){
		initBeforeAfterSwipe();
	});

	$('.accordion-list').each(function(ind, el) {
		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
	});

	$('.phone-click').on('click', function(){
		var phoneNumber = $(this).data('number');		

		if (typeof gtag === 'function') {
			gtag('event', 'Phone call', {
				'event_category': 'phone call tracking',
				'event_action': 'Click to Call',
				'event_label': phoneNumber
			});
		}
	});

	headerBorder = $('[data-purpose="header-scroll-border"]');
	header = $('.main-header');

	if (headerBorder.length === 0) {
		headerBorder = $('.main-header');
	}
	if (headerBorder && headerBorder.length > 0) {
		var headerScrollFn = function() {
			var height = headerBorder.outerHeight() + headerBorder.offset().top;
			var scrollTop = $(window).scrollTop();

			if (height <= scrollTop && !header.hasClass('opened-fix-menu')) {
				header.addClass('opened-fix-menu');
				$('body').addClass('opened-fix-menu');
			} else if (height > scrollTop && header.hasClass('opened-fix-menu')) {
				header.removeClass('opened-fix-menu');
				$('body').removeClass('opened-fix-menu');
			}
		};

		window.addEventListener('touchend', headerScrollFn);
		window.addEventListener('touchmove', headerScrollFn);
	  	window.addEventListener('scroll', headerScrollFn);
	}
});

function checkTrustBoxPosition() {
	var scrollTop = $(window).scrollTop();

	$.each(trustBoxes, function(){
		if (!$(this).hasClass('active')) {
			var top = $(this).offset().top;

			if ((top + 100) < scrollTop + window.innerHeight) {
				$(this).addClass('active');
			}
		}
	});

	if (boxCounter == trustBoxes.length) {
		breakBoxCheck = true;
	}
}

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="'+remodal_id+'"]');
	if(tmp.length > 0){
	  tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}
		
		gtag('event', action, evtData);
	}
	else if(typeof ga != 'undefined' && action && category){
		if(tag && tag.trim() != ''){
			ga('send', 'event', action, category, tag);
		}else{
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode){
    var scriptTag = document.createElement('script');
    scriptTag.src = url;

    scriptTag.onload = implementationCode;
    scriptTag.onreadystatechange = implementationCode;

    document.body.appendChild(scriptTag);
};

function setNavigationUrl(item) {
	var address = item.data('address');
	
	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if(device == 'iphone' || device == 'ipad' || device == 'ipod'){
			function iOSversion() {
					
				if (/iP(hone|od|ad)/.test(navigator.platform)) {
				  var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
				  return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}
		
		var urlSuffix = '/?daddr='+address;
		var nav_url = '';
		switch(protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url+urlSuffix);
	}
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');
	
	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	} 
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) 
{
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic:true,
		dynamicEl:items,
		index:index,
		hash:false,
		download: false
	});
}

function getMapStyle()
{
	return [];
}

function initGalleries()
{
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function(){
			$(this).addClass('initialized');
			$(this).click(function(){
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					initGallery($(this), mediaGroupItems[id]);
				}
			});
		});
	}
}

function initBeforeAfterSwipe()
{
	var counter = 0;
	$('[data-purpose="before-after-swipe"]:not(.initialized)').each(function(){
		$(this).twentytwenty({
			no_overlay: true,
			default_offset_pct: 0.2
		});

		$(this).addClass('initialized');
		counter++;
	});

	if (counter > 0) {
		setTimeout(function(){
			$(window).trigger('resize');
		}, 50);
	}
}

function addBeforeAfterLoaded(el)
{
	var img = $(el);
	var index = $(img).data('index');

	if (typeof beforeAfterLoaded[index] === 'undefined') {
		beforeAfterLoaded[index] = 0;
	}

	beforeAfterLoaded[index]++;

	if (beforeAfterLoaded[index] == 2) {
		var parent = img.parent();
		if (!parent.hasClass("initialized")) {
			parent.twentytwenty({
				no_overlay: true,
				default_offset_pct: 0.2
			});
	
			parent.addClass('initialized');
		}
	}
}